import styled from "styled-components"

import { HREF_MINUT_STORE } from "src/constants/hrefs"
import { useTranslate } from "src/i18n/useTranslate"
import { MButton } from "src/ui/Button/MButton"
import { semanticGood } from "src/ui/colors"
import CheckoutSuccess from "src/ui/icons/checkmark-round.svg"
import { ExternalLink } from "src/ui/Link/ExternalLink"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export function ChangePlanSuccess() {
  const { t, langKeys } = useTranslate()

  return (
    <Box>
      <Content>
        <IconBox>
          <CheckoutSuccess width="70" />
        </IconBox>
        <MText variant="heading1">
          {t(langKeys.change_plan_success_title)}
        </MText>
        <div>{t(langKeys.change_plan_success_body)}</div>
        <div>
          <div>
            {t(langKeys.need_more_sensors_question)}{" "}
            <ExternalLink href={HREF_MINUT_STORE}>
              {t(langKeys.go_to_the_store)}
            </ExternalLink>
          </div>
        </div>
        <ButtonBox>
          {/* TODO WEB-313: We currently redirect the user back to the root, since this will
          give the backend more time to react to the webhook, thus leading to increased chances that
          the user will see the new updated plan when the account page loads the next time. We should
          improve this so that the user is instantly updated somehow */}
          <MButton onClick={() => (window.location.href = "/")}>
            {t(langKeys.go_to_dashboard)}
          </MButton>
        </ButtonBox>
      </Content>
    </Box>
  )
}

const Box = styled.div`
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${spacing.M};
`

const Content = styled.div`
  max-width: 650px;
  text-align: center;
  display: grid;
  gap: ${spacing.M};
`

const ButtonBox = styled.div`
  margin-top: ${spacing.M};
`

const IconBox = styled.div`
  max-width: 70px;
  width: 100%;
  margin: 0 auto;
  color: ${semanticGood};
`
